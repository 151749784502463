<script>
import { ref } from 'vue';
import { Thumbs } from 'swiper';

import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";
import PageHeader from "@/components/page-header";

import SwiperCore, { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";

SwiperCore.use([Pagination, Navigation]);

export default {
    page: {
        title: "Commentaires",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    setup() {
        const thumbsSwiper = ref(null);
        const setThumbsSwiper = (swiper) => {
            thumbsSwiper.value = swiper;
        };
        return {
            Thumbs,
            thumbsSwiper,
            setThumbsSwiper,
        };
    },
    data() {
        return {
            title: "Commentaires",
            items: [
                {
                    text: "Ecommerce",
                    href: "/",
                },
                {
                    text: "Commentaires",
                    active: true,
                },
            ],
            productDetailsWidgets: [
                {
                    id: 1,
                    icon: "ri-money-dollar-circle-fill",
                    label: "Price",
                    labelDetail: "$120.40",
                },
                {
                    id: 2,
                    icon: "ri-file-copy-2-fill",
                    label: "No. of Orders",
                    labelDetail: "2,234",
                },
                {
                    id: 3,
                    icon: "ri-stack-fill",
                    label: "Available Stocks",
                    labelDetail: "1,230",
                },
                {
                    id: 4,
                    icon: "ri-inbox-archive-fill",
                    label: "Total Revenue",
                    labelDetail: "$60,645",
                },
            ],
        };
    },
    components: {
        Layout,
        PageHeader,
        Swiper,
        SwiperSlide,
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row gx-lg-5">
                            <div class="col-xl-4 col-md-8 mx-auto">
                                <div class="product-img-slider sticky-side-div">
                                    <swiper :modules="[Thumbs]" class="product-thumbnail-slider p-2 rounded bg-light"
                                        :navigation="{
                                          nextEl: '.swiper-button-next bg-white shadow',
                                          prevEl: '.swiper-button-prev bg-white shadow',
                                        }" :thumbs="{
                                          swiper: thumbnailSwiper,
                                        
                                        
                                        }">
                                        <swiper-slide>
                                            <img src="@/assets/images/products/img-8.png" alt
                                                class="img-fluid d-block" />
                                        </swiper-slide>
                                        <swiper-slide>
                                            <img src="@/assets/images/products/img-6.png" alt
                                                class="img-fluid d-block" />
                                        </swiper-slide>
                                        <swiper-slide>
                                            <img src="@/assets/images/products/img-1.png" alt
                                                class="img-fluid d-block" />
                                        </swiper-slide>
                                        <swiper-slide>
                                            <img src="@/assets/images/products/img-8.png" alt
                                                class="img-fluid d-block" />
                                        </swiper-slide>

                                    </swiper>
                                    <div class="swiper-button-next"></div>
                                    <div class="swiper-button-prev"></div>
                                    <!-- end swiper thumbnail slide -->
                                    <swiper :modules="[Thumbs]" class="product-nav-slider mt-2" :loop="false"
                                        :spaceBetween="10" :slidesPerView="4" :freeMode="true" watch-slides-progress
                                        @swiper="setThumbsSwiper">
                                        <swiper-slide>
                                            <div class="nav-slide-item">
                                                <img src="@/assets/images/products/img-8.png" alt
                                                    class="img-fluid d-block" />
                                            </div>
                                        </swiper-slide>
                                        <swiper-slide>
                                            <div class="nav-slide-item">
                                                <img src="@/assets/images/products/img-6.png" alt
                                                    class="img-fluid d-block" />
                                            </div>
                                        </swiper-slide>
                                        <swiper-slide>
                                            <div class="nav-slide-item">
                                                <img src="@/assets/images/products/img-1.png" alt
                                                    class="img-fluid d-block" />
                                            </div>
                                        </swiper-slide>
                                        <swiper-slide>
                                            <div class="nav-slide-item">
                                                <img src="@/assets/images/products/img-8.png" alt
                                                    class="img-fluid d-block" />
                                            </div>
                                        </swiper-slide>
                                    </swiper>
                                    <!-- end swiper nav slide -->
                                </div>
                            </div>
                            <!-- end col -->

                            <div class="col-xl-8">
                                <div class="mt-xl-0 mt-5">
                                    <div class="d-flex">
                                        <div class="flex-grow-1">
                                            <h4>Full Sleeve Sweatshirt for Men (Pink)</h4>
                                            <div class="hstack gap-3 flex-wrap">
                                                <div>
                                                    <a href="#" class="text-primary d-block">Tommy Hilfiger</a>
                                                </div>
                                                <div class="vr"></div>
                                                <div class="text-muted">
                                                    Vendeur :
                                                    <span class="text-body fw-medium">Zoetic Fashion</span>
                                                </div>
                                                <div class="vr"></div>
                                                <div class="text-muted">
                                                    Publie :
                                                    <span class="text-body fw-medium">26 Mar, 2021</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex-shrink-0">
                                            <div>
                                                <router-link to="/add-product" class="btn btn-light"
                                                    data-bs-toggle="tooltip" data-bs-placement="top" title="Edit">
                                                    <i class="ri-pencil-fill align-bottom"></i>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mt-4 text-muted">
                                        <h4 class="fs-14"><strong>Commentaires</strong> :</h4>
                                        <div class="row">
                                            <div class="col-1">
                                                <img src="@/assets/images/products/img-1.png" alt height="75" width="75"
                                                    class="img-fluid d-block" />
                                            </div>
                                            <div class="col-11">
                                                <strong>Raoul </strong> <br>
                                                Tommy Hilfiger men striped pink sweatshirt. Crafted with
                                                cotton. Material composition is 100% organic cotton. This
                                                is one of the world’s leading designer lifestyle brands
                                                and is internationally recognized for celebrating the
                                                essence of classic American cool style, featuring preppy
                                                with a twist designs.
                                                <div class="text-muted text-end">
                                                    Publie :
                                                    <span class="text-body fw-medium">26 Mar, 2021</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-1">
                                                <img src="@/assets/images/products/img-1.png" alt height="75" width="75"
                                                    class="img-fluid d-block" />
                                            </div>
                                            <div class="col-11">
                                                <strong>Raoul </strong> <br>
                                                Tommy Hilfiger men striped pink sweatshirt. Crafted with
                                                cotton. Material composition is 100% organic cotton. This
                                                is one of the world’s leading designer lifestyle brands
                                                and is internationally recognized for celebrating the
                                                essence of classic American cool style, featuring preppy
                                                with a twist designs.
                                                <div class="text-muted text-end">
                                                    Publie :
                                                    <span class="text-body fw-medium">26 Mar, 2021</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-1">
                                                <img src="@/assets/images/products/img-1.png" alt height="75" width="75"
                                                    class="img-fluid d-block" />
                                            </div>
                                            <div class="col-11">
                                                <strong>Raoul </strong> <br>
                                                Tommy Hilfiger men striped pink sweatshirt. Crafted with
                                                cotton. Material composition is 100% organic cotton. This
                                                is one of the world’s leading designer lifestyle brands
                                                and is internationally recognized for celebrating the
                                                essence of classic American cool style, featuring preppy
                                                with a twist designs.
                                                <div class="text-muted text-end">
                                                    Publie :
                                                    <span class="text-body fw-medium">26 Mar, 2021</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <!-- end card body -->
                                </div>
                            </div>
                            <!-- end col -->
                        </div>
                        <!-- end row -->
                    </div>
                    <!-- end card body -->
                </div>
                <!-- end card -->
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </Layout>
</template>
